exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apply-form-components-banner-js": () => import("./../../../src/pages/ApplyForm/components/Banner.js" /* webpackChunkName: "component---src-pages-apply-form-components-banner-js" */),
  "component---src-pages-apply-form-index-js": () => import("./../../../src/pages/ApplyForm/index.js" /* webpackChunkName: "component---src-pages-apply-form-index-js" */),
  "component---src-pages-blog-components-all-blog-js": () => import("./../../../src/pages/Blog/components/AllBlog.js" /* webpackChunkName: "component---src-pages-blog-components-all-blog-js" */),
  "component---src-pages-blog-components-banner-js": () => import("./../../../src/pages/Blog/components/Banner.js" /* webpackChunkName: "component---src-pages-blog-components-banner-js" */),
  "component---src-pages-blog-components-blog-js": () => import("./../../../src/pages/Blog/components/Blog.js" /* webpackChunkName: "component---src-pages-blog-components-blog-js" */),
  "component---src-pages-blog-components-letest-blog-js": () => import("./../../../src/pages/Blog/components/LetestBlog.js" /* webpackChunkName: "component---src-pages-blog-components-letest-blog-js" */),
  "component---src-pages-blog-components-news-letter-js": () => import("./../../../src/pages/Blog/components/NewsLetter.js" /* webpackChunkName: "component---src-pages-blog-components-news-letter-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/Blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/ContactUs/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-home-client-award-counter-js": () => import("./../../../src/pages/Home/ClientAward/Counter.js" /* webpackChunkName: "component---src-pages-home-client-award-counter-js" */),
  "component---src-pages-home-client-award-index-js": () => import("./../../../src/pages/Home/ClientAward/index.js" /* webpackChunkName: "component---src-pages-home-client-award-index-js" */),
  "component---src-pages-home-components-banner-section-js": () => import("./../../../src/pages/Home/components/BannerSection.js" /* webpackChunkName: "component---src-pages-home-components-banner-section-js" */),
  "component---src-pages-home-components-case-study-js": () => import("./../../../src/pages/Home/components/CaseStudy.js" /* webpackChunkName: "component---src-pages-home-components-case-study-js" */),
  "component---src-pages-home-components-hire-section-js": () => import("./../../../src/pages/Home/components/HireSection.js" /* webpackChunkName: "component---src-pages-home-components-hire-section-js" */),
  "component---src-pages-home-components-horizontal-scroll-js": () => import("./../../../src/pages/Home/components/HorizontalScroll.js" /* webpackChunkName: "component---src-pages-home-components-horizontal-scroll-js" */),
  "component---src-pages-home-components-letstalk-form-js": () => import("./../../../src/pages/Home/components/LetstalkForm.js" /* webpackChunkName: "component---src-pages-home-components-letstalk-form-js" */),
  "component---src-pages-home-components-portfolio-js": () => import("./../../../src/pages/Home/components/Portfolio.js" /* webpackChunkName: "component---src-pages-home-components-portfolio-js" */),
  "component---src-pages-home-components-product-section-js": () => import("./../../../src/pages/Home/components/ProductSection.js" /* webpackChunkName: "component---src-pages-home-components-product-section-js" */),
  "component---src-pages-home-components-services-js": () => import("./../../../src/pages/Home/components/Services.js" /* webpackChunkName: "component---src-pages-home-components-services-js" */),
  "component---src-pages-home-components-techstack-js": () => import("./../../../src/pages/Home/components/Techstack.js" /* webpackChunkName: "component---src-pages-home-components-techstack-js" */),
  "component---src-pages-home-components-we-serve-js": () => import("./../../../src/pages/Home/components/WeServe.js" /* webpackChunkName: "component---src-pages-home-components-we-serve-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/Home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-home-technology-components-banner-js": () => import("./../../../src/pages/homeTechnology/components/Banner.js" /* webpackChunkName: "component---src-pages-home-technology-components-banner-js" */),
  "component---src-pages-home-technology-components-hire-react-developer-faq-js": () => import("./../../../src/pages/homeTechnology/components/HireReactDeveloperFAQ.js" /* webpackChunkName: "component---src-pages-home-technology-components-hire-react-developer-faq-js" */),
  "component---src-pages-home-technology-components-hiring-process-js": () => import("./../../../src/pages/homeTechnology/components/HiringProcess.js" /* webpackChunkName: "component---src-pages-home-technology-components-hiring-process-js" */),
  "component---src-pages-home-technology-components-logo-slider-js": () => import("./../../../src/pages/homeTechnology/components/LogoSlider.js" /* webpackChunkName: "component---src-pages-home-technology-components-logo-slider-js" */),
  "component---src-pages-home-technology-components-react-other-service-js": () => import("./../../../src/pages/homeTechnology/components/ReactOtherService.js" /* webpackChunkName: "component---src-pages-home-technology-components-react-other-service-js" */),
  "component---src-pages-home-technology-components-what-we-do-js": () => import("./../../../src/pages/homeTechnology/components/WhatWeDo.js" /* webpackChunkName: "component---src-pages-home-technology-components-what-we-do-js" */),
  "component---src-pages-home-technology-components-why-choose-react-js": () => import("./../../../src/pages/homeTechnology/components/WhyChooseReact.js" /* webpackChunkName: "component---src-pages-home-technology-components-why-choose-react-js" */),
  "component---src-pages-home-technology-components-why-choose-us-js": () => import("./../../../src/pages/homeTechnology/components/WhyChooseUs.js" /* webpackChunkName: "component---src-pages-home-technology-components-why-choose-us-js" */),
  "component---src-pages-home-technology-components-your-choise-js": () => import("./../../../src/pages/homeTechnology/components/YourChoise.js" /* webpackChunkName: "component---src-pages-home-technology-components-your-choise-js" */),
  "component---src-pages-home-technology-index-js": () => import("./../../../src/pages/homeTechnology/index.js" /* webpackChunkName: "component---src-pages-home-technology-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-at-logicrays-components-banner-js": () => import("./../../../src/pages/JobsAtLogicrays/components/banner.js" /* webpackChunkName: "component---src-pages-jobs-at-logicrays-components-banner-js" */),
  "component---src-pages-jobs-at-logicrays-components-best-offers-js": () => import("./../../../src/pages/JobsAtLogicrays/components/BestOffers.js" /* webpackChunkName: "component---src-pages-jobs-at-logicrays-components-best-offers-js" */),
  "component---src-pages-jobs-at-logicrays-components-current-opening-js": () => import("./../../../src/pages/JobsAtLogicrays/components/CurrentOpening.js" /* webpackChunkName: "component---src-pages-jobs-at-logicrays-components-current-opening-js" */),
  "component---src-pages-jobs-at-logicrays-components-employee-reviews-js": () => import("./../../../src/pages/JobsAtLogicrays/components/EmployeeReviews.js" /* webpackChunkName: "component---src-pages-jobs-at-logicrays-components-employee-reviews-js" */),
  "component---src-pages-jobs-at-logicrays-components-festival-celebrations-js": () => import("./../../../src/pages/JobsAtLogicrays/components/FestivalCelebrations.js" /* webpackChunkName: "component---src-pages-jobs-at-logicrays-components-festival-celebrations-js" */),
  "component---src-pages-jobs-at-logicrays-components-why-to-join-js": () => import("./../../../src/pages/JobsAtLogicrays/components/WhyToJoin.js" /* webpackChunkName: "component---src-pages-jobs-at-logicrays-components-why-to-join-js" */),
  "component---src-pages-jobs-at-logicrays-index-js": () => import("./../../../src/pages/JobsAtLogicrays/index.js" /* webpackChunkName: "component---src-pages-jobs-at-logicrays-index-js" */),
  "component---src-pages-life-at-logicrays-components-banner-js": () => import("./../../../src/pages/LifeAtLogicrays/components/banner.js" /* webpackChunkName: "component---src-pages-life-at-logicrays-components-banner-js" */),
  "component---src-pages-life-at-logicrays-components-new-memories-js": () => import("./../../../src/pages/LifeAtLogicrays/components/NewMemories.js" /* webpackChunkName: "component---src-pages-life-at-logicrays-components-new-memories-js" */),
  "component---src-pages-life-at-logicrays-components-old-memories-js": () => import("./../../../src/pages/LifeAtLogicrays/components/OldMemories.js" /* webpackChunkName: "component---src-pages-life-at-logicrays-components-old-memories-js" */),
  "component---src-pages-life-at-logicrays-components-our-value-js": () => import("./../../../src/pages/LifeAtLogicrays/components/OurValue.js" /* webpackChunkName: "component---src-pages-life-at-logicrays-components-our-value-js" */),
  "component---src-pages-life-at-logicrays-components-why-join-logicrays-js": () => import("./../../../src/pages/LifeAtLogicrays/components/WhyJoinLogicrays.js" /* webpackChunkName: "component---src-pages-life-at-logicrays-components-why-join-logicrays-js" */),
  "component---src-pages-life-at-logicrays-index-js": () => import("./../../../src/pages/LifeAtLogicrays/index.js" /* webpackChunkName: "component---src-pages-life-at-logicrays-index-js" */),
  "component---src-pages-magento-product-components-banner-js": () => import("./../../../src/pages/MagentoProduct/components/banner.js" /* webpackChunkName: "component---src-pages-magento-product-components-banner-js" */),
  "component---src-pages-magento-product-components-extensions-store-js": () => import("./../../../src/pages/MagentoProduct/components/ExtensionsStore.js" /* webpackChunkName: "component---src-pages-magento-product-components-extensions-store-js" */),
  "component---src-pages-magento-product-components-magento-services-js": () => import("./../../../src/pages/MagentoProduct/components/MagentoServices.js" /* webpackChunkName: "component---src-pages-magento-product-components-magento-services-js" */),
  "component---src-pages-magento-product-components-our-partner-js": () => import("./../../../src/pages/MagentoProduct/components/OurPartner.js" /* webpackChunkName: "component---src-pages-magento-product-components-our-partner-js" */),
  "component---src-pages-magento-product-index-js": () => import("./../../../src/pages/MagentoProduct/index.js" /* webpackChunkName: "component---src-pages-magento-product-index-js" */),
  "component---src-pages-portfolio-list-components-banner-js": () => import("./../../../src/pages/PortfolioList/components/Banner.js" /* webpackChunkName: "component---src-pages-portfolio-list-components-banner-js" */),
  "component---src-pages-portfolio-list-components-project-list-js": () => import("./../../../src/pages/PortfolioList/components/projectList.js" /* webpackChunkName: "component---src-pages-portfolio-list-components-project-list-js" */),
  "component---src-pages-portfolio-list-index-js": () => import("./../../../src/pages/PortfolioList/index.js" /* webpackChunkName: "component---src-pages-portfolio-list-index-js" */),
  "component---src-pages-portfolio-new-aoe-aoe-js": () => import("./../../../src/pages/PortfolioNew/Aoe/Aoe.js" /* webpackChunkName: "component---src-pages-portfolio-new-aoe-aoe-js" */),
  "component---src-pages-portfolio-new-aubrey-allen-aubrey-allen-js": () => import("./../../../src/pages/PortfolioNew/AubreyAllen/AubreyAllen.js" /* webpackChunkName: "component---src-pages-portfolio-new-aubrey-allen-aubrey-allen-js" */),
  "component---src-pages-portfolio-new-branded-stuff-branded-stuff-js": () => import("./../../../src/pages/PortfolioNew/BrandedStuff/BrandedStuff.js" /* webpackChunkName: "component---src-pages-portfolio-new-branded-stuff-branded-stuff-js" */),
  "component---src-pages-portfolio-new-cd-keys-cd-keys-js": () => import("./../../../src/pages/PortfolioNew/CDKeys/CDKeys.js" /* webpackChunkName: "component---src-pages-portfolio-new-cd-keys-cd-keys-js" */),
  "component---src-pages-portfolio-new-evergreen-goods-evergreen-good-js": () => import("./../../../src/pages/PortfolioNew/EvergreenGoods/EvergreenGood.js" /* webpackChunkName: "component---src-pages-portfolio-new-evergreen-goods-evergreen-good-js" */),
  "component---src-pages-portfolio-new-fytme-fytme-js": () => import("./../../../src/pages/PortfolioNew/Fytme/Fytme.js" /* webpackChunkName: "component---src-pages-portfolio-new-fytme-fytme-js" */),
  "component---src-pages-portfolio-new-girde-shop-gride-shop-js": () => import("./../../../src/pages/PortfolioNew/GirdeShop/GrideShop.js" /* webpackChunkName: "component---src-pages-portfolio-new-girde-shop-gride-shop-js" */),
  "component---src-pages-portfolio-new-rapid-dreams-components-about-js": () => import("./../../../src/pages/PortfolioNew/RapidDreams/components/about.js" /* webpackChunkName: "component---src-pages-portfolio-new-rapid-dreams-components-about-js" */),
  "component---src-pages-portfolio-new-rapid-dreams-components-admin-panel-js": () => import("./../../../src/pages/PortfolioNew/RapidDreams/components/AdminPanel.js" /* webpackChunkName: "component---src-pages-portfolio-new-rapid-dreams-components-admin-panel-js" */),
  "component---src-pages-portfolio-new-rapid-dreams-components-banner-js": () => import("./../../../src/pages/PortfolioNew/RapidDreams/components/banner.js" /* webpackChunkName: "component---src-pages-portfolio-new-rapid-dreams-components-banner-js" */),
  "component---src-pages-portfolio-new-rapid-dreams-components-dreamers-app-js": () => import("./../../../src/pages/PortfolioNew/RapidDreams/components/DreamersApp.js" /* webpackChunkName: "component---src-pages-portfolio-new-rapid-dreams-components-dreamers-app-js" */),
  "component---src-pages-portfolio-new-rapid-dreams-components-key-feature-js": () => import("./../../../src/pages/PortfolioNew/RapidDreams/components/KeyFeature.js" /* webpackChunkName: "component---src-pages-portfolio-new-rapid-dreams-components-key-feature-js" */),
  "component---src-pages-portfolio-new-rapid-dreams-components-results-js": () => import("./../../../src/pages/PortfolioNew/RapidDreams/components/Results.js" /* webpackChunkName: "component---src-pages-portfolio-new-rapid-dreams-components-results-js" */),
  "component---src-pages-portfolio-new-rapid-dreams-components-service-you-get-js": () => import("./../../../src/pages/PortfolioNew/RapidDreams/components/ServiceYouGet.js" /* webpackChunkName: "component---src-pages-portfolio-new-rapid-dreams-components-service-you-get-js" */),
  "component---src-pages-portfolio-new-rapid-dreams-components-tech-stack-js": () => import("./../../../src/pages/PortfolioNew/RapidDreams/components/TechStack.js" /* webpackChunkName: "component---src-pages-portfolio-new-rapid-dreams-components-tech-stack-js" */),
  "component---src-pages-portfolio-new-rapid-dreams-components-user-application-js": () => import("./../../../src/pages/PortfolioNew/RapidDreams/components/UserApplication.js" /* webpackChunkName: "component---src-pages-portfolio-new-rapid-dreams-components-user-application-js" */),
  "component---src-pages-portfolio-new-rapid-dreams-index-js": () => import("./../../../src/pages/PortfolioNew/RapidDreams/index.js" /* webpackChunkName: "component---src-pages-portfolio-new-rapid-dreams-index-js" */),
  "component---src-pages-portfolio-new-shot-gun-shot-gun-js": () => import("./../../../src/pages/PortfolioNew/ShotGun/ShotGun.js" /* webpackChunkName: "component---src-pages-portfolio-new-shot-gun-shot-gun-js" */),
  "component---src-pages-portfolio-new-stock-trade-stock-trade-js": () => import("./../../../src/pages/PortfolioNew/StockTrade/StockTrade.js" /* webpackChunkName: "component---src-pages-portfolio-new-stock-trade-stock-trade-js" */),
  "component---src-pages-portfolio-new-suppsrus-suppsrus-js": () => import("./../../../src/pages/PortfolioNew/Suppsrus/Suppsrus.js" /* webpackChunkName: "component---src-pages-portfolio-new-suppsrus-suppsrus-js" */),
  "component---src-pages-portfolio-new-tictac-learn-tictac-learn-js": () => import("./../../../src/pages/PortfolioNew/TictacLearn/TictacLearn.js" /* webpackChunkName: "component---src-pages-portfolio-new-tictac-learn-tictac-learn-js" */),
  "component---src-pages-sanjay-modasia-components-blog-js": () => import("./../../../src/pages/SanjayModasia/components/Blog.js" /* webpackChunkName: "component---src-pages-sanjay-modasia-components-blog-js" */),
  "component---src-pages-sanjay-modasia-index-js": () => import("./../../../src/pages/SanjayModasia/index.js" /* webpackChunkName: "component---src-pages-sanjay-modasia-index-js" */),
  "component---src-pages-service-category-component-banner-js": () => import("./../../../src/pages/serviceCategory/component/Banner.js" /* webpackChunkName: "component---src-pages-service-category-component-banner-js" */),
  "component---src-pages-service-category-component-our-offerings-js": () => import("./../../../src/pages/serviceCategory/component/OurOfferings.js" /* webpackChunkName: "component---src-pages-service-category-component-our-offerings-js" */),
  "component---src-pages-service-category-component-service-category-why-choose-js": () => import("./../../../src/pages/serviceCategory/component/ServiceCategoryWhyChoose.js" /* webpackChunkName: "component---src-pages-service-category-component-service-category-why-choose-js" */),
  "component---src-pages-service-category-component-service-testimonials-js": () => import("./../../../src/pages/serviceCategory/component/ServiceTestimonials.js" /* webpackChunkName: "component---src-pages-service-category-component-service-testimonials-js" */),
  "component---src-pages-service-category-index-js": () => import("./../../../src/pages/serviceCategory/index.js" /* webpackChunkName: "component---src-pages-service-category-index-js" */),
  "component---src-pages-service-detail-components-ai-ml-detail-js": () => import("./../../../src/pages/serviceDetail/components/AiMlDetail.js" /* webpackChunkName: "component---src-pages-service-detail-components-ai-ml-detail-js" */),
  "component---src-pages-service-detail-components-ai-ml-service-js": () => import("./../../../src/pages/serviceDetail/components/AiMlService.js" /* webpackChunkName: "component---src-pages-service-detail-components-ai-ml-service-js" */),
  "component---src-pages-service-detail-components-all-technology-js": () => import("./../../../src/pages/serviceDetail/components/AllTechnology.js" /* webpackChunkName: "component---src-pages-service-detail-components-all-technology-js" */),
  "component---src-pages-service-detail-components-android-app-service-js": () => import("./../../../src/pages/serviceDetail/components/AndroidAppService.js" /* webpackChunkName: "component---src-pages-service-detail-components-android-app-service-js" */),
  "component---src-pages-service-detail-components-aws-process-services-js": () => import("./../../../src/pages/serviceDetail/components/AwsProcessServices.js" /* webpackChunkName: "component---src-pages-service-detail-components-aws-process-services-js" */),
  "component---src-pages-service-detail-components-aws-solutions-js": () => import("./../../../src/pages/serviceDetail/components/AwsSolutions.js" /* webpackChunkName: "component---src-pages-service-detail-components-aws-solutions-js" */),
  "component---src-pages-service-detail-components-azure-expertise-js": () => import("./../../../src/pages/serviceDetail/components/AzureExpertise.js" /* webpackChunkName: "component---src-pages-service-detail-components-azure-expertise-js" */),
  "component---src-pages-service-detail-components-azure-process-services-js": () => import("./../../../src/pages/serviceDetail/components/AzureProcessServices.js" /* webpackChunkName: "component---src-pages-service-detail-components-azure-process-services-js" */),
  "component---src-pages-service-detail-components-back-end-service-js": () => import("./../../../src/pages/serviceDetail/components/BackEndService.js" /* webpackChunkName: "component---src-pages-service-detail-components-back-end-service-js" */),
  "component---src-pages-service-detail-components-banner-js": () => import("./../../../src/pages/serviceDetail/components/Banner.js" /* webpackChunkName: "component---src-pages-service-detail-components-banner-js" */),
  "component---src-pages-service-detail-components-big-commerce-service-process-js": () => import("./../../../src/pages/serviceDetail/components/BigCommerceServiceProcess.js" /* webpackChunkName: "component---src-pages-service-detail-components-big-commerce-service-process-js" */),
  "component---src-pages-service-detail-components-cloud-solution-js": () => import("./../../../src/pages/serviceDetail/components/CloudSolution.js" /* webpackChunkName: "component---src-pages-service-detail-components-cloud-solution-js" */),
  "component---src-pages-service-detail-components-ecommerce-website-design-js": () => import("./../../../src/pages/serviceDetail/components/EcommerceWebsiteDesign.js" /* webpackChunkName: "component---src-pages-service-detail-components-ecommerce-website-design-js" */),
  "component---src-pages-service-detail-components-ecommerce-website-js": () => import("./../../../src/pages/serviceDetail/components/EcommerceWebsite.js" /* webpackChunkName: "component---src-pages-service-detail-components-ecommerce-website-js" */),
  "component---src-pages-service-detail-components-high-end-shopify-js": () => import("./../../../src/pages/serviceDetail/components/HighEndShopify.js" /* webpackChunkName: "component---src-pages-service-detail-components-high-end-shopify-js" */),
  "component---src-pages-service-detail-components-high-end-woo-commerce-js": () => import("./../../../src/pages/serviceDetail/components/HighEndWooCommerce.js" /* webpackChunkName: "component---src-pages-service-detail-components-high-end-woo-commerce-js" */),
  "component---src-pages-service-detail-components-hire-shopify-team-js": () => import("./../../../src/pages/serviceDetail/components/HireShopifyTeam.js" /* webpackChunkName: "component---src-pages-service-detail-components-hire-shopify-team-js" */),
  "component---src-pages-service-detail-components-hire-woo-commerce-js": () => import("./../../../src/pages/serviceDetail/components/HireWooCommerce.js" /* webpackChunkName: "component---src-pages-service-detail-components-hire-woo-commerce-js" */),
  "component---src-pages-service-detail-components-ios-app-service-js": () => import("./../../../src/pages/serviceDetail/components/IosAppService.js" /* webpackChunkName: "component---src-pages-service-detail-components-ios-app-service-js" */),
  "component---src-pages-service-detail-components-magento-2-upgrade-service-js": () => import("./../../../src/pages/serviceDetail/components/Magento2UpgradeService.js" /* webpackChunkName: "component---src-pages-service-detail-components-magento-2-upgrade-service-js" */),
  "component---src-pages-service-detail-components-magento-development-js": () => import("./../../../src/pages/serviceDetail/components/MagentoDevelopment.js" /* webpackChunkName: "component---src-pages-service-detail-components-magento-development-js" */),
  "component---src-pages-service-detail-components-magento-development-services-js": () => import("./../../../src/pages/serviceDetail/components/MagentoDevelopmentServices.js" /* webpackChunkName: "component---src-pages-service-detail-components-magento-development-services-js" */),
  "component---src-pages-service-detail-components-magento-entension-js": () => import("./../../../src/pages/serviceDetail/components/MagentoEntension.js" /* webpackChunkName: "component---src-pages-service-detail-components-magento-entension-js" */),
  "component---src-pages-service-detail-components-magento-extension-list-js": () => import("./../../../src/pages/serviceDetail/components/MagentoExtensionList.js" /* webpackChunkName: "component---src-pages-service-detail-components-magento-extension-list-js" */),
  "component---src-pages-service-detail-components-magento-migration-js": () => import("./../../../src/pages/serviceDetail/components/MagentoMigration.js" /* webpackChunkName: "component---src-pages-service-detail-components-magento-migration-js" */),
  "component---src-pages-service-detail-components-magento-migration-process-js": () => import("./../../../src/pages/serviceDetail/components/MagentoMigrationProcess.js" /* webpackChunkName: "component---src-pages-service-detail-components-magento-migration-process-js" */),
  "component---src-pages-service-detail-components-magento-service-process-js": () => import("./../../../src/pages/serviceDetail/components/MagentoServiceProcess.js" /* webpackChunkName: "component---src-pages-service-detail-components-magento-service-process-js" */),
  "component---src-pages-service-detail-components-magento-services-js": () => import("./../../../src/pages/serviceDetail/components/MagentoServices.js" /* webpackChunkName: "component---src-pages-service-detail-components-magento-services-js" */),
  "component---src-pages-service-detail-components-magento-services-list-js": () => import("./../../../src/pages/serviceDetail/components/MagentoServicesList.js" /* webpackChunkName: "component---src-pages-service-detail-components-magento-services-list-js" */),
  "component---src-pages-service-detail-components-magento-solution-js": () => import("./../../../src/pages/serviceDetail/components/MagentoSolution.js" /* webpackChunkName: "component---src-pages-service-detail-components-magento-solution-js" */),
  "component---src-pages-service-detail-components-magento-upgrade-process-js": () => import("./../../../src/pages/serviceDetail/components/MagentoUpgradeProcess.js" /* webpackChunkName: "component---src-pages-service-detail-components-magento-upgrade-process-js" */),
  "component---src-pages-service-detail-components-magento-version-upgrade-js": () => import("./../../../src/pages/serviceDetail/components/MagentoVersionUpgrade.js" /* webpackChunkName: "component---src-pages-service-detail-components-magento-version-upgrade-js" */),
  "component---src-pages-service-detail-components-nlp-application-js": () => import("./../../../src/pages/serviceDetail/components/NLPApplication.js" /* webpackChunkName: "component---src-pages-service-detail-components-nlp-application-js" */),
  "component---src-pages-service-detail-components-other-magento-service-js": () => import("./../../../src/pages/serviceDetail/components/OtherMagentoService.js" /* webpackChunkName: "component---src-pages-service-detail-components-other-magento-service-js" */),
  "component---src-pages-service-detail-components-other-magento-service-new-js": () => import("./../../../src/pages/serviceDetail/components/OtherMagentoServiceNew.js" /* webpackChunkName: "component---src-pages-service-detail-components-other-magento-service-new-js" */),
  "component---src-pages-service-detail-components-otherbigcommerce-js": () => import("./../../../src/pages/serviceDetail/components/Otherbigcommerce.js" /* webpackChunkName: "component---src-pages-service-detail-components-otherbigcommerce-js" */),
  "component---src-pages-service-detail-components-saas-services-js": () => import("./../../../src/pages/serviceDetail/components/SaasServices.js" /* webpackChunkName: "component---src-pages-service-detail-components-saas-services-js" */),
  "component---src-pages-service-detail-components-service-technology-js": () => import("./../../../src/pages/serviceDetail/components/ServiceTechnology.js" /* webpackChunkName: "component---src-pages-service-detail-components-service-technology-js" */),
  "component---src-pages-service-detail-components-services-js": () => import("./../../../src/pages/serviceDetail/components/Services.js" /* webpackChunkName: "component---src-pages-service-detail-components-services-js" */),
  "component---src-pages-service-detail-components-services-why-choose-js": () => import("./../../../src/pages/serviceDetail/components/ServicesWhyChoose.js" /* webpackChunkName: "component---src-pages-service-detail-components-services-why-choose-js" */),
  "component---src-pages-service-detail-components-shopify-development-services-js": () => import("./../../../src/pages/serviceDetail/components/ShopifyDevelopmentServices.js" /* webpackChunkName: "component---src-pages-service-detail-components-shopify-development-services-js" */),
  "component---src-pages-service-detail-components-shopify-services-js": () => import("./../../../src/pages/serviceDetail/components/ShopifyServices.js" /* webpackChunkName: "component---src-pages-service-detail-components-shopify-services-js" */),
  "component---src-pages-service-detail-components-ux-prototyping-detail-js": () => import("./../../../src/pages/serviceDetail/components/UXPrototypingDetail.js" /* webpackChunkName: "component---src-pages-service-detail-components-ux-prototyping-detail-js" */),
  "component---src-pages-service-detail-components-ux-prototyping-js": () => import("./../../../src/pages/serviceDetail/components/UXPrototyping.js" /* webpackChunkName: "component---src-pages-service-detail-components-ux-prototyping-js" */),
  "component---src-pages-service-detail-components-woo-commerce-devlopment-service-js": () => import("./../../../src/pages/serviceDetail/components/WooCommerceDevlopmentService.js" /* webpackChunkName: "component---src-pages-service-detail-components-woo-commerce-devlopment-service-js" */),
  "component---src-pages-service-detail-index-js": () => import("./../../../src/pages/serviceDetail/index.js" /* webpackChunkName: "component---src-pages-service-detail-index-js" */),
  "component---src-pages-service-detail-service-details-js": () => import("./../../../src/pages/serviceDetail/serviceDetails.js" /* webpackChunkName: "component---src-pages-service-detail-service-details-js" */),
  "component---src-pages-servise-lending-page-component-banner-js": () => import("./../../../src/pages/serviseLendingPage/component/Banner.js" /* webpackChunkName: "component---src-pages-servise-lending-page-component-banner-js" */),
  "component---src-pages-servise-lending-page-component-client-award-counter-js": () => import("./../../../src/pages/serviseLendingPage/component/ClientAward/Counter.js" /* webpackChunkName: "component---src-pages-servise-lending-page-component-client-award-counter-js" */),
  "component---src-pages-servise-lending-page-component-client-award-index-js": () => import("./../../../src/pages/serviseLendingPage/component/ClientAward/index.js" /* webpackChunkName: "component---src-pages-servise-lending-page-component-client-award-index-js" */),
  "component---src-pages-servise-lending-page-component-ecommerce-service-js": () => import("./../../../src/pages/serviseLendingPage/component/EcommerceService.js" /* webpackChunkName: "component---src-pages-servise-lending-page-component-ecommerce-service-js" */),
  "component---src-pages-servise-lending-page-component-life-cycle-js": () => import("./../../../src/pages/serviseLendingPage/component/LifeCycle.js" /* webpackChunkName: "component---src-pages-servise-lending-page-component-life-cycle-js" */),
  "component---src-pages-servise-lending-page-component-web-solution-js": () => import("./../../../src/pages/serviseLendingPage/component/WebSolution.js" /* webpackChunkName: "component---src-pages-servise-lending-page-component-web-solution-js" */),
  "component---src-pages-servise-lending-page-index-js": () => import("./../../../src/pages/serviseLendingPage/index.js" /* webpackChunkName: "component---src-pages-servise-lending-page-index-js" */),
  "component---src-pages-technology-component-backend-technology-js": () => import("./../../../src/pages/Technology/component/BackendTechnology.js" /* webpackChunkName: "component---src-pages-technology-component-backend-technology-js" */),
  "component---src-pages-technology-component-banner-js": () => import("./../../../src/pages/Technology/component/Banner.js" /* webpackChunkName: "component---src-pages-technology-component-banner-js" */),
  "component---src-pages-technology-component-ecommerce-technology-js": () => import("./../../../src/pages/Technology/component/EcommerceTechnology.js" /* webpackChunkName: "component---src-pages-technology-component-ecommerce-technology-js" */),
  "component---src-pages-technology-component-emerging-technology-js": () => import("./../../../src/pages/Technology/component/EmergingTechnology.js" /* webpackChunkName: "component---src-pages-technology-component-emerging-technology-js" */),
  "component---src-pages-technology-component-frontend-technology-js": () => import("./../../../src/pages/Technology/component/FrontendTechnology.js" /* webpackChunkName: "component---src-pages-technology-component-frontend-technology-js" */),
  "component---src-pages-technology-component-marketing-branding-js": () => import("./../../../src/pages/Technology/component/MarketingBranding.js" /* webpackChunkName: "component---src-pages-technology-component-marketing-branding-js" */),
  "component---src-pages-technology-component-mobile-technology-js": () => import("./../../../src/pages/Technology/component/MobileTechnology.js" /* webpackChunkName: "component---src-pages-technology-component-mobile-technology-js" */),
  "component---src-pages-technology-index-js": () => import("./../../../src/pages/Technology/index.js" /* webpackChunkName: "component---src-pages-technology-index-js" */),
  "component---src-pages-thank-you-index-js": () => import("./../../../src/pages/ThankYou/index.js" /* webpackChunkName: "component---src-pages-thank-you-index-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-who-we-are-component-banner-js": () => import("./../../../src/pages/WhoWeAre/component/Banner.js" /* webpackChunkName: "component---src-pages-who-we-are-component-banner-js" */),
  "component---src-pages-who-we-are-component-company-leadership-js": () => import("./../../../src/pages/WhoWeAre/component/CompanyLeadership.js" /* webpackChunkName: "component---src-pages-who-we-are-component-company-leadership-js" */),
  "component---src-pages-who-we-are-component-journey-of-logic-rays-js": () => import("./../../../src/pages/WhoWeAre/component/JourneyOfLogicRays.js" /* webpackChunkName: "component---src-pages-who-we-are-component-journey-of-logic-rays-js" */),
  "component---src-pages-who-we-are-component-logicrays-expertise-js": () => import("./../../../src/pages/WhoWeAre/component/LogicraysExpertise.js" /* webpackChunkName: "component---src-pages-who-we-are-component-logicrays-expertise-js" */),
  "component---src-pages-who-we-are-component-logicrays-values-js": () => import("./../../../src/pages/WhoWeAre/component/LogicraysValues.js" /* webpackChunkName: "component---src-pages-who-we-are-component-logicrays-values-js" */),
  "component---src-pages-who-we-are-component-our-mission-js": () => import("./../../../src/pages/WhoWeAre/component/OurMission.js" /* webpackChunkName: "component---src-pages-who-we-are-component-our-mission-js" */),
  "component---src-pages-who-we-are-component-who-we-are-sec-js": () => import("./../../../src/pages/WhoWeAre/component/WhoWeAreSec.js" /* webpackChunkName: "component---src-pages-who-we-are-component-who-we-are-sec-js" */),
  "component---src-pages-who-we-are-component-wthat-we-have-for-you-js": () => import("./../../../src/pages/WhoWeAre/component/WthatWeHaveForYou.js" /* webpackChunkName: "component---src-pages-who-we-are-component-wthat-we-have-for-you-js" */),
  "component---src-pages-who-we-are-index-js": () => import("./../../../src/pages/WhoWeAre/index.js" /* webpackChunkName: "component---src-pages-who-we-are-index-js" */),
  "component---src-templates-blog-details-index-js": () => import("./../../../src/templates/BlogDetails/index.js" /* webpackChunkName: "component---src-templates-blog-details-index-js" */)
}

